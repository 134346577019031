import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ConsultaDeudaComponent } from "./consulta-deuda/consulta-deuda.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ComConsultaService } from "./services/com-consulta.service";
import { ApiInterceptor } from "./api.interceptor";
import { DiscountPipe } from "./discount.pipe";
import { SatPopoverModule } from "@ncstate/sat-popover";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { environment } from "src/environments/environment";
import { ToastrModule } from "ngx-toastr";

@NgModule({
  declarations: [AppComponent, ConsultaDeudaComponent, DiscountPipe],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FontAwesomeModule,
    SatPopoverModule,
    SweetAlert2Module.forRoot(),
    RecaptchaV3Module,
    ToastrModule.forRoot()
  ],
  providers: [
    ComConsultaService,
    DiscountPipe,
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
