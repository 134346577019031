export class Cliente {

  consumerCode: string;
  meterNumber: string;
  keyService: string;
  response: string;

  constructor() {
    if (this.consumerCode === undefined) {
      this.consumerCode = null;
    }
    if (this.meterNumber === undefined) {
      this.meterNumber = null;
    }
    if (this.keyService === undefined) {
      this.keyService = null;
    }
  }
}
