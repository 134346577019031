import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Cliente } from '../models/cliente';
import { Deuda } from '../models/deuda';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';

const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

@Injectable({
  providedIn: 'root'
})
export class ComConsultaService {
  apiEndPoint = '';
  constructor(private http: HttpClient) {
    this.apiEndPoint = environment.apiEndPoint;
  }

  consultarDeuda(obj: Cliente): Observable<Deuda> {
    obj.keyService = 'wCRJDvFqx3ahNuS28SW9efS9';
    return this.http.post<Deuda>(this.apiEndPoint, JSON.stringify(obj), httpOptions).pipe();
  }
}
