import {
  Component,
  OnInit,
  ChangeDetectorRef,
  AfterViewInit,
} from "@angular/core";
import { Cliente } from "../models/cliente";
import { Deuda } from "../models/deuda";
import { DetalleDeuda } from "../models/detalleDeuda";
import { ComConsultaService } from "../services/com-consulta.service";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import {
  faInfo,
  faSearch,
  faTrash,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { Subscription } from "rxjs";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-consulta-deuda",
  templateUrl: "./consulta-deuda.component.html",
  styleUrls: ["./consulta-deuda.component.css"],
})
export class ConsultaDeudaComponent implements OnInit, AfterViewInit {
  faInfo = faInfo;
  faSearch = faSearch;
  faTrash = faTrash;
  faDownload = faDownload;

  //  declaración de variables
  FormBusqueda: UntypedFormGroup;
  MensajeBusqueda = false;
  MostrarLoader = false;
  MostrarDeuda = false;
  recaptchaStr = "";
  mensaje = "";
  cliente = new Cliente();
  deuda = new Deuda();
  colDeudas: DetalleDeuda[];

  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;
  public captchaIsReady = false;

  public theme: "light";
  public size: "normal";
  public lang = "es";
  public type: "image";
  public useGlobalDomain = false;
  public badge: "bottomright" | "bottomleft" | "inline" = "bottomright";

  public recaptcha: any = null;

  reCAPTCHAToken: string = "";
  tokenVisible: boolean = false;


  constructor(
    public consultaServicio: ComConsultaService,
    private formBuilder: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private recaptchaV3Service: ReCaptchaV3Service,
    private toastr: ToastrService
  ) {
    this.FormBusqueda = formBuilder.group({
      numeroConsumidor: ["", [Validators.required, Validators.pattern("[0-9]*"),]],
      numeroMedidor: ["", [Validators.pattern("[0-9]*")]],
      recaptcha: [""],
    });
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.captchaIsLoaded = true;
    this.cdr.detectChanges();
  }

  LimpiarDatos() {
    this.FormBusqueda.patchValue({
      numeroConsumidor: "",
      numeroMedidor: "",
      recaptcha: "",
    });
    this.MostrarLoader = false;
    this.MensajeBusqueda = false;
    this.MostrarDeuda = false;
  }

  obtenerDeuda() {
    this.MensajeBusqueda = false;
    this.MostrarDeuda = false;
    this.cliente.consumerCode = this.FormBusqueda.value.numeroConsumidor;
    this.cliente.meterNumber = this.FormBusqueda.value.numeroMedidor;

    if (
      this.cliente.consumerCode.trim() !== "" ||
      this.cliente.meterNumber.trim() !== ""
    ) {
      this.recaptchaV3Service.execute('consultaDeuda').subscribe((token: string) => {
        this.tokenVisible = true;
        this.cliente.response = token;
        this.MostrarLoader = true;
        this.consultaServicio.consultarDeuda(this.cliente).subscribe(
          (resultado) => {
            this.MostrarLoader = false;
            if (resultado.result === 1) {
              this.mensaje =
                "El código de consumidor o código de medidor no son correctos verifíquelos y vuelva a consultar";
              this.MensajeBusqueda = true;
            } else {
              this.deuda = resultado;
              this.MostrarDeuda = true;
              this.MensajeBusqueda = false;
            }
          },
          (error) => {
            this.MostrarLoader = false;
            this.toastr.error("Error al procesar la solicitud", 'Consulta de Deuda',);
          }
        );
      });

    } else {
      this.mensaje =
        "Debe ingresar el número de consumidor o el número de medidor";
      this.MensajeBusqueda = true;
    }
  }

}
