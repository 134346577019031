<nav class="navbar navbar-light flex-column flex-md-row navedb">
  <a class="navbar-brand" href="https://www.endedelbeni.bo/">
    <img src="https://edbinfo.endedelbeni.bo/consultadeuda/assets/img/logo-ende-del-beni.png" width="159" height="83"
      alt="ENDE DELBENI S.A.M.">
  </a>
  <a class="navbar-brand" href="#">
    <img src="https://edbinfo.endedelbeni.bo/consultadeuda/assets/img/ende1.png" width="152" height="75"
      alt="ENDE COORPORACION">
  </a>
</nav>
<div id="content-wrapper" class="d-flex flex-column">
  <div id="content">
    <br />
    <div class="container-fluid">
      <form [formGroup]="FormBusqueda">
        <div class="alert alert-danger" *ngIf="MensajeBusqueda">
          <strong>¡{{ mensaje }}!</strong>
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <div class="row">
              <div class="mb-3 col-sm-12 col-md-6">
                <div class="card p-2">
                  <p class="list-group-item list-group-item-info mensajeCliente">
                    Puede consultar su deuda pendiente a través del siguiente
                    formulario, ingresando su Número de Cliente o su Número de
                    Medidor, el que tenga disponible.
                  </p>
                  <label><b>Número de Cliente</b></label>
                  <div class="input-group mb-3">
                    <input type="text" formControlName="numeroConsumidor" (keyup)="MensajeBusqueda = false"
                      class="form-control" placeholder="Ej. 987654" aria-label="Numero de Consumidor"
                      aria-describedby="basic-addon2" maxlength="8" />
                    <div class="input-group-append">
                      <button type="button" class="btn btn-secondary mr-2" placement="right" [satPopoverAnchor]="p"
                        (click)="obtenerDeuda()">
                        <fa-icon [icon]="faInfo"></fa-icon>
                      </button>
                      <sat-popover #p horizontalAlign="after" verticalAlign="above">
                        <div class="info-wrapper">
                          <div class="tooltip-wrapper">
                            El Número de Cliente puede obtenerlo de su última
                            factura.!
                          </div>
                        </div>
                      </sat-popover>
                    </div>
                  </div>
                  <div class="alert alert-danger" *ngIf="
                      FormBusqueda.controls['numeroConsumidor'].hasError(
                        'pattern'
                      )
                    ">
                    <strong>¡Esta ingresado caracteres invalidos!</strong>
                  </div>
                  <label><b>Número de Medidor</b></label>
                  <div class="input-group mb-3">
                    <input type="text" formControlName="numeroMedidor" (keyup)="MensajeBusqueda = false"
                      class="form-control" placeholder="Ej. 123456" aria-label="Numero de Medidor"
                      aria-describedby="help2" maxlength="8" />
                    <div class="input-group-append">
                      <button type="button" class="btn btn-secondary mr-2" placement="right" [satPopoverAnchor]="q"
                        (click)="obtenerDeuda()">
                        <fa-icon [icon]="faInfo"></fa-icon>
                      </button>
                      <sat-popover #q horizontalAlign="after" verticalAlign="above">
                        <div class="info-wrapper">
                          <div class="tooltip-wrapper">
                            El Número de Medidor puede obtenerlo de su última
                            factura
                          </div>
                        </div>
                      </sat-popover>
                    </div>
                  </div>
                  <div class="alert alert-danger" *ngIf="
                      FormBusqueda.controls['numeroMedidor'].hasError('pattern')
                    ">
                    <strong>¡Esta ingresado caracteres inválidos!</strong>
                  </div>
                  <br />

                  <br />

                  <div class="row">
                    <div class="mb-3 col-sm-12 col-md-6">
                      <button class="btn btn-block btn-consulta" type="button"
                        [disabled]="!FormBusqueda.valid || MostrarLoader" (click)="obtenerDeuda()">
                        <fa-icon [icon]="faSearch"></fa-icon> Consultar Deuda
                      </button>
                    </div>
                    <div class="mb-3 col-sm-12 col-md-6">
                      <button class="btn btn-block btn-clean" type="button" [disabled]="MostrarLoader"
                        (click)="LimpiarDatos()">
                        <fa-icon [icon]="faTrash"></fa-icon> Limpiar datos
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3 col-sm-12 col-md-6">
                <div class="card p-2">
                  <h4 class="mb-3 mt-1 mb-4">Detalle Deuda Actual</h4>
                  <div *ngIf="MostrarDeuda" class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr class="uk-text-bold">
                          <th scope="col">Periodo</th>
                          <th scope="col">Monto</th>
                          <th scope="col">Vencimiento</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr *ngFor="let detail of deuda.accountDetail">
                          <td>{{ detail.period }}</td>
                          <td>{{ detail.debt | number: ".2-5" }} Bs.</td>
                          <td>{{ detail.expirationDate }}</td>
                        </tr>
                        <tr></tr>
                      </tbody>
                    </table>
                  </div>
                  <div *ngIf="MostrarDeuda" class="col-md-12">
                    <div class="text-center total">
                      TOTAL: {{ deuda.totalAccount | number: ".2-5" }} Bs.
                    </div>
                  </div>
                </div>
                <div class="card p-2">
                  <h4 class="mb-3 mt-1 mb-4">
                    Detalle Pagos Realizados (3 Últimos )
                  </h4>
                  <div *ngIf="MostrarDeuda" class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr class="uk-text-bold">
                          <th scope="col">Periodo</th>
                          <th scope="col">Monto</th>
                          <th scope="col">Fecha de Pago</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let payments of deuda.accountPayments">
                          <td>{{ payments.period }}</td>
                          <td>{{ payments.debt | number: ".2-5" }} Bs.</td>
                          <td>{{ payments.paymentDate }}</td>
                        <tr></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </form>
      <br />
    </div>
    <div class="spinner-box" *ngIf="MostrarLoader">
      <div class="circle-border">
        <div class="circle-core"></div>
      </div>
    </div>
  </div>

  <footer class="sticky-footer footer">
    <div class="container">
      <br />
      <div class="copyright text-center footer-et">
        <span>Copyright &copy; ENDE TECNOLOGÍAS S.A. 2023</span>
      </div>
    </div>
  </footer>
</div>