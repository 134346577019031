import { DetalleDeuda } from './detalleDeuda';

export class Deuda {
    result: number;
    message: string;
    totalAccount: number;
    consumerCode: number;
    consumerName: string;
    accountDetail: DetalleDeuda[];
    accountPayments: DetalleDeuda[];

    constructor() {
        if (this.result === undefined) {
            this.result = null;
        }
        if (this.message === undefined) {
            this.message = null;
        }
        if (this.totalAccount === undefined) {
            this.totalAccount = null;
        }
        if (this.consumerCode === undefined) {
            this.consumerCode = null;
        }
        if (this.consumerName === undefined) {
            this.consumerName = null;
        }
        if (this.accountDetail === undefined) {
            this.accountDetail = [];
        }
        if (this.accountPayments === undefined) {
            this.accountPayments = [];
        }
    }
}