import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ConsultaDeudaComponent } from "./consulta-deuda/consulta-deuda.component";

const routes: Routes = [
  { path: "deuda-pendiente", component: ConsultaDeudaComponent },
  { path: "", redirectTo: "deuda-pendiente", pathMatch: "full" },
  { path: "**", redirectTo: "deuda-pendiente", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
