import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'discount'
})
export class DiscountPipe implements PipeTransform {

  transform(value: number): string {
    let newStr = '';
    if (value > 0) {
      newStr = value + '%';
    } else {
      newStr = '-';
    }
    return newStr;
  }

}
